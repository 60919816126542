























































































import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import '../assets/styles.scss';
import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid, SfLink } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import HeroSection from '~/components/HeroSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ProductsCarousel from '~/modules/catalog/product/components/ProductsCarousel.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    SfLink,
    CallToAction: () => import(/* webpackPrefetch: true */ "~/components/CallToAction.vue"),
    InstagramFeed: () => import(/* webpackPrefetch: true */ "~/components/InstagramFeed.vue"),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ "~/components/MobileStoreBanner.vue"),
    NewProducts: () => import(/* webpackPrefetch: true */ "~/components/NewProducts.vue"),
    WomenProducts: () => import(/* webpackPrefetch: true */ "~/components/WomenProducts.vue"),
    MenProducts: () => import(/* webpackPrefetch: true */ "~/components/MenProducts.vue"),
    BagsProduct: () => import(/* webpackPrefetch: true */ "~/components/BagsProduct.vue"),
    ElectronicProduct: () => import(/* webpackPrefetch: true */ "~/components/ElectronicProduct.vue"),
    ProductsCarousel,
    VueSlickCarousel
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { isDesktop } = app.$device;

    const page = ref<CmsPage | null>(null);

    const hero = ref([
      {
        title: '',
        subtitle: '',
        buttonText: 'shop now',
        imageSrc: '/homepage/talo_banner1.webp',
        imageWidth: isDesktop ? 2150 : 328,
        imageHeight: isDesktop ? 930 : 224,
        // imageWidth: !isDesktop && 380,
        // imageHeight: !isDesktop && 224,
        imageConfig: {
          fit: 'contain',
          format: 'webp',
        },
        link: '/women.html',
      },
      {
        title: '',
        subtitle: '',
        buttonText: 'shop now',
        imageSrc: '/homepage/Banner1.webp',
        imageWidth: isDesktop ? 2150 : 328,
        imageHeight: isDesktop ? 930 : 224,
        // imageWidth: !isDesktop && 380,
        // imageHeight: !isDesktop && 224,
        imageConfig: {
          fit: 'contain',
          format: 'webp',
        },
        link: '/women.html',
      },
      {
        title: '',
        subtitle: '',
        buttonText: 'shop now',
        imageSrc: '/homepage/Banner3.webp',
         imageWidth: isDesktop ? 2150 : 328,
        imageHeight: isDesktop ? 930 : 224,
        // imageWidth: !isDesktop && 380,
        // imageHeight: !isDesktop && 224,
        imageConfig: {
          fit: 'contain',
          format: 'webp',
        },
        link: '/women.html',
      }
    ]);

    const banners = ref([
      {
        slot: 'banner-A',
        subtitle:'Dresses',
        title:'Cocktail & Party',
        description:'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        buttonText:'Shop now',
        image: {
          mobile:
            '/homepage/bannerB.webp',
          desktop:
            '/homepage/bannerF.webp',
        },
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 840 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim desktop-only',
        link: '/women/women-clothing-skirts',
      },
      {
        slot:'banner-B',
        subtitle:'',
        title:'Linen Dresses',
        description:'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        buttonText: 'shop now',
        image: '/homepage/talo_banner_image.png',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 496 : 328,
          height: isDesktop ? 840 : 343,
          format: 'png',
        },
        class: 'sf-banner--slim banner-central desktop-only',
        link: '/women/women-clothing-dresses',
      },
      {
        slot: 'banner-C',
        subtitle: app.i18n.t('T-Shirts'),
        title: app.i18n.t('The Office Life'),
        image: '/homepage/bannerC.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim banner__tshirt',
        link: '/women/women-clothing-shirts',
      },
      {
        slot: 'banner-D',
        subtitle: app.i18n.t('Summer Sandals'),
        title: app.i18n.t('Eco Sandals'),
        image: '/homepage/bannerG.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim',
        link: '/women/women-shoes-sandals',
      },
    ]);

    const callToAction = ref({
      title: app.i18n.t('Subscribe to Newsletters'),
      description: app.i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!'),
      buttonText: app.i18n.t('Subscribe'),
      imageSrc: '/homepage/newsletter.webp',
      imageWidth: isDesktop ? 1240 : 400,
      imageHeight: isDesktop ? 202 : 200,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
    });

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // @ts-ignore
    return {
      banners,
      callToAction,
      hero,
      page,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
